import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/pl/chapter_5/sub_2/slide1';
import Slide2 from 'src/slides/desktop/pl/chapter_5/sub_2/slide2';
import WrapperMobile from 'src/slides/mobile/pl/chapter_5/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Bitwa, która ocaliła Europę i świat I Bitwa Warszawska" lang="pl" description="Sukces pod Warszawą zapewnił Europie spokój na 20 lat." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO
      title="Nowy Świat | Bitwa Warszawska"
      lang="pl"
      description="Świat po wojnie"
    />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;

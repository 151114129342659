import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_5/sub_2/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-5-2-2"}}) {
      body
    }
    before: mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-5-2-2-before"}}) {
      body
    },
    after: mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-5-2-2-after"}}) {
        body
    },
    leftText: mdx(
      frontmatter: {
        language: { eq: "PL" }
        title: { eq: "slide-5-2-2-left-mobile" }
      }
    ) {
      body
    }
    rightText: mdx(
      frontmatter: {
        language: { eq: "PL" }
        title: { eq: "slide-5-2-2-right-mobile" }
      }
    ) {
      body
    }
  }
  `);
  return (
    <Template query={query} title="Część II" />
  );
};


export default Slide;
